import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import face from '../assets/images/facebook.svg';
import lin from '../assets/images/Linkdln.svg';
import twiter from '../assets/images/x.svg';

import linkedin from '../assets/images/linkedin.png';

import '../assets/scss/_socialMedia.scss';

export default function SocialMedia({ data }) {
  return (
    <>
      <div className="author-profile-bg p-3 mb-4">
        <div className="author-flex">
          <img
            className="author-profile-image me-2"
            src={data?.author?.profileImage?.file?.url}
            alt="author"
          />

          <a
            href={data?.author?.linkedInUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="d-none d-md-inline-block align-bottom "
          >
            <img className="ms-lg-3" src={linkedin} alt="linkedin" />
          </a>
          <div className="d-flex flex-column justify-content-between">
            <p className="author-name text-white my-1 my-lg-3 my-md-3">
              {data?.author?.name}
              <a
                href={data?.author?.linkedInUrl}
                target="_blank"
                rel="noopener noreferrer"
                className=" ms-2 align-bottom d-md-none"
              >
                <img
                  className="ms-lg-3 linked-in"
                  src={linkedin}
                  alt="linkedin"
                />
              </a>
            </p>

            <div className="author-designation text-white">
              {data?.author?.designation}
            </div>
          </div>
        </div>

        <hr className="text-white d-none d-lg-block"></hr>
        <p className="author-description text-white  mt-3 mt-md-0">
          {data?.author?.description}
        </p>
      </div>

      <div className="community-socialmedia-bg p-3 d-flex flex-row flex-lg-column justify-content-between align-items-center align-items-lg-start">
        <label className="icon-style mb-lg-2">Share with your community!</label>
        <div className="d-flex justify-content-start">
          <a
            href="https://www.linkedin.com/company/bugs-work"
            target="_blank"
            rel="noopener noreferrer"
            className="icon-square"
          >
            <img src={face} alt="facebook" className="mx-2 " />
          </a>
          <a
            href="https://www.facebook.com/bugsdotwork"
            target="_blank"
            rel="noopener noreferrer"
            className="icon-square"
          >
            <img src={lin} alt="linkedin" className="mx-2" />
          </a>
          <a
            href="https://x.com/BugsWork "
            target="_blank"
            rel="noopener noreferrer"
            className="icon-square"
          >
            <img src={twiter} alt="twitter" className="mx-2" />
          </a>
        </div>
      </div>
    </>
  );
}
