import React, { useState } from 'react';
import { graphql, Link } from 'gatsby';
import { format } from 'date-fns';
import { Container, Row, Col, Tooltip, Button } from 'react-bootstrap';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Wrapper from '../components/Wrapper';
import Seo from '../shared/seo';
import Acnavbar from '../components/Acnavbar';
import Footer from '../components/footer';
import BlogCard from '../components/BlogCard';
import SocialMedia from '../components/SocialMedia';
import useWindowLocation from '../customhooks/useWindowLocation';

import shareFriend from '../assets/images/Frame 57 1.svg';
import face from '../assets/images/facebook.svg';
import lin from '../assets/images/Linkdln.svg';
import twiter from '../assets/images/x.svg';

import '../assets/scss/_detailBlog.scss';

function DetailBlog({ pageContext, data }) {
  const [tooltipText, setTooltipText] = useState('Click to copy link');
  const location = useWindowLocation();
  const [activeButton, setActiveButton] = useState(pageContext?.slug);

  const [activeIndex, setActiveIndex] = useState(0);

  const handleIndex = (index: number) => {
    setActiveIndex(index);
    document
      .getElementById(`section-${index}`)
      .scrollIntoView({ behavior: 'smooth' });
  };

  const copyLink = () => {
    navigator.clipboard.writeText(location);
    setTooltipText('Article URL Copied!');
    setTimeout(() => {
      setTooltipText('Click to copy link');
    }, 3000);
  };

  const tooltip = (
    <Tooltip id="tooltipText" className="tooltip-custom">
      {tooltipText}
    </Tooltip>
  );

  const options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <span className="">{text}</span>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <div className="">
          <p className="my-2 my-lg-4 text-para">{children}</p>
        </div>
      ),
      [BLOCKS.HEADING_1]: (node, children) => (
        <h2 className="text-heading">{children}</h2>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <h2 className="text-heading">{children}</h2>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <h2 className="text-heading">{children}</h2>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const imageID = node.data.target.sys.id;
        const asset = pageContext?.body?.references?.find(
          (ref) => ref.contentful_id === imageID
        );

        if (asset) {
          return (
            <div className="my-2 d-flex flex-column">
              <LazyLoadImage
                effect="blur"
                src={asset.file.url}
                className="img-fluid my-4"
                alt={asset.title}
              />
            </div>
          );
        }
      },
    },
  };

  const filteredArticles = data.allContentfulBlogArticle.edges
    .map((edge) => edge.node)
    .filter((article) => article !== pageContext.pageData);

  const handleClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  return (
    <Wrapper>
      <Seo
        title={pageContext?.seoTitle}
        description={pageContext.seoDescription}
      />
      <div>
        <Acnavbar
          home={false}
          pathname={'BLOG-PAGE'}
          logo={''}
          logoLogic={false}
        />

        <Container id="detail-blog-page">
          <div className="mt-12">
            <div className="">
              <div className="mb-2 navigation-path px-1 d-flex align-items-center">
                <Link to="/" className="ms-1 fw-500">
                  <Button
                    variant="link"
                    className={
                      activeButton === 'home'
                        ? 'active pb-1'
                        : 'text-decoration-none p-0'
                    }
                    onClick={() => handleClick('home')}
                  >
                    Home
                  </Button>
                </Link>
                <span className="arrow-color mx-2">{' > '}</span>
                <Link to="/resource/tutorial/" className="fw-500">
                  <Button
                    variant="link"
                    className={
                      activeButton === 'blog'
                        ? 'active'
                        : 'text-decoration-none p-0'
                    }
                    onClick={() => handleClick('blog')}
                  >
                    Blog
                  </Button>
                </Link>
                <span className="arrow-color mx-2">{' > '}</span>
                <Link to={`/resource/${pageContext?.slug}`} className="fw-500">
                  <Button
                    variant="link"
                    className={
                      activeButton === pageContext?.slug
                        ? 'active text-decoration-none p-0 slug-color'
                        : 'text-decoration-none p-0'
                    }
                    onClick={() => handleClick(pageContext?.slug)}
                  >
                    {pageContext?.slug}
                  </Button>
                </Link>
              </div>
            </div>
          </div>

          <div className="d-flex flex-column flex-lg-row justify-content-between ">
            <div className="col-lg-9 col-12 mb-3 mb-lg-0 me-lg-3 position-relative">
              <LazyLoadImage
                effect="blur"
                className="img-fluid preview-image mb-3"
                src={pageContext?.previewImage?.file?.url}
                alt={pageContext?.previewImage?.title}
              />

              <div className="blog-details-info ps-3 pt-3 pb-2 pe-2">
                <div className="categories-title  mb-3 d-flex align-items-center ps-2">
                  <div className="dot me-2"></div>
                  <div className='fw-400 fnt-14 category-text'>
                    {pageContext?.Categories.map(
                      (category) => category.title
                    ).join(', ')}
                  </div>
                </div>

                <h1 className="title main-blog-title text-white mb-2 mb-lg-3  fw-700">
                  {pageContext?.title}
                </h1>
                <div className="info-meta d-flex align-items-center">
                  <p className="created-at data-time text-white">
                    {format(new Date(pageContext?.createdAt), 'MMMM dd')}
                  </p>
                  <p className="time-to-read ms-3 data-time text-white">
                    <span className="ms-2">{pageContext?.timeToRead}</span> min
                    read
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-12">
              <SocialMedia data={pageContext} />
            </div>
          </div>
          <Row className="d-none d-md-flex">
            <Col xs={12} md={9} lg={9}>
              <div className="pb-4 mt-4 ">
                {pageContext?.body &&
                  pageContext.body.map((item, index) => (
                    <div key={index} className="mb-lg-4">
                      {item.title && (
                        <h2 id={`section-${index}`}>{item.title}</h2>
                      )}
                      {item.image && item.image.file && (
                        <LazyLoadImage
                          effect="blur"
                          src={item.image.file.url}
                          alt={item.image.file.fileName}
                          className="img-fluid my-4"
                        />
                      )}
                      {item.description &&
                        renderRichText(item.description, options)}
                    </div>
                  ))}
              </div>
              <div className="share-section p-3">
                <div className="d-flex flex-row justify-content-md-between align-items-center">
                  <div className="share-text mb-3 mb-md-0">
                    Like what you see? Share with a friend.
                  </div>
                  <div className="d-flex justify-content-center justify-content-md-start">
                    <a
                      href="https://www.facebook.com/bugsdotwork"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="icon-square"
                    >
                      <img src={face} alt="facebook" className="mx-2" />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/bugs-work"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="icon-square"
                    >
                      <img src={lin} alt="linkedin" className="mx-2" />
                    </a>
                    <a
                      href="https://x.com/BugsWork"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="icon-square"
                    >
                      <img src={twiter} alt="twitter" className="mx-2" />
                    </a>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} md={3} lg={3}>
              <div className="ms-3">
                <div>
                  <div className="article-style mt-3 mb-3">In this article</div>
                </div>
                <div>
                  {pageContext?.body &&
                    pageContext.body.map((item, index) => (
                      <div key={index} className="mb-4">
                        {item.title && (
                          <a
                            href={`#section-${index}`}
                            className={`article-point-style my-2 ${activeIndex === index ? 'active' : ''}`}
                            onClick={() => handleIndex(index)}
                          >
                            {item.title}
                          </a>
                        )}
                      </div>
                    ))}
                </div>
              </div>
            </Col>
          </Row>

          <Row className="d-md-none">
            <Col xs={12} md={3} lg={3}>
              <div className="ms-lg-3">
                <div className="article-style mt-4 mb-2">In this article</div>

                <div className="">
                  {pageContext?.body &&
                    pageContext.body.map((item, index) => (
                      <div key={index} className="mb-2 mb-lg-4">
                        {item.title && (
                          <a
                            href={`#section-${index}`}
                            className={`article-point-style ${activeIndex === index ? 'active' : ''}`}
                            onClick={() => handleIndex(index)}
                          >
                            {item.title}
                          </a>
                        )}
                      </div>
                    ))}
                </div>
              </div>
            </Col>
            <Col xs={12} md={9} lg={9}>
              <div className="pb-4 mt-4">
                {pageContext?.body &&
                  pageContext.body.map((item, index) => (
                    <div key={index} className="mb-4">
                      {item.title && (
                        <div className="section-title" id={`section-${index}`}>
                          {item.title}
                        </div>
                      )}
                      {item.image && item.image.file && (
                        <LazyLoadImage
                          effect="blur"
                          src={item.image.file.url}
                          alt={item.image.file.fileName}
                          className="img-fluid my-4"
                        />
                      )}
                      <div className="item-description">
                        {item.description &&
                          renderRichText(item.description, options)}
                      </div>
                    </div>
                  ))}
              </div>
              <div className="share-section">
                <div className="d-flex flex-row justify-content-between align-items-center p-2">
                  <div className="share-text">
                    Like what you see? Share with a friend.
                  </div>
                  <div className="d-flex justify-content-center justify-content-md-start">
                    <a
                      href="https://facebook.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="icon-square"
                    >
                      <img src={face} alt="facebook" className="mx-2" />
                    </a>
                    <a
                      href="https://linkedin.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="icon-square"
                    >
                      <img src={lin} alt="linkedin" className="mx-2" />
                    </a>
                    <a
                      href="https://twitter.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="icon-square"
                    >
                      <img src={twiter} alt="twitter" className="mx-2" />
                    </a>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          {/* <hr className="mt-0 mt-lg-7 mt-md-7"></hr> */}
        </Container>

        <div className="py-4 py-lg-5">
          <Container>
            <div className=" text-start color-black text-heading">
              Related Articles
            </div>
            <BlogCard data={filteredArticles} />
          </Container>
        </div>

        <Footer pathname="blogs" />
      </div>
    </Wrapper>
  );
}

export default DetailBlog;

export const query = graphql`
  query PagesQuery {
    allContentfulBlogArticle(
      filter: { Categories: { elemMatch: { title: {} } } }
      sort: { fields: createdAt, order: ASC }
      limit: 3
    ) {
      edges {
        node {
          id
          title
          seoTitle
          timeToRead
          slug
          seoDescription
          createdAt
          author {
            id
            name
            designation
            description
            linkedInUrl
            profileImage {
              file {
                contentType
                fileName
                url
              }
            }
          }
          previewImage {
            file {
              fileName
              url
              contentType
            }
            gatsbyImageData(layout: FIXED)
          }
          Categories {
            id
            title
            contentful_id
            slug
          }
          body {
            id
            title
            image {
              file {
                contentType
                fileName
                url
              }
            }
            description {
              raw
            }
          }
        }
      }
    }
  }
`;
